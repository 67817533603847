// -----------------------------------------------------------------------------------------------------
// @ Custom color maps for Fuse
// -----------------------------------------------------------------------------------------------------
@use "sass:map";
@use "@angular/material" as mat;
mat.$theme-ignore-duplication-warnings: true;
// -----------------------------------------------------------------------------------------------------
// @ Component theming
// -----------------------------------------------------------------------------------------------------

// Import app and page component themes here to enable theming for them
@import "styles/palette";
@import "src/app/layout/components/chat-panel/chat-panel.theme";
@import "src/app/layout/components/toolbar/toolbar.theme";
@import "src/@infiot-fuse/directives/inf-disable-form.theme";
@import "src/@infiot-fuse/components/inf-chart/inf-chart.theme";

// Define a mixin for easier access
@mixin components-theme($theme) {
  // Layout components
  @include chat-panel-theme($theme);
  @include toolbar-theme($theme);

  @include inf-disable-form-theme($theme);
  @include inf-chart-theme($theme);
}

// -----------------------------------------------------------------------------------------------------
// @ Define the default theme (Netskope)
// -----------------------------------------------------------------------------------------------------

// Define the primary, accent and warn palettes
$default-primary-palette: mat.define-palette($netskope-blue, 500);
$default-accent-palette: mat.define-palette($netskope-blue, 300);
$default-warn-palette: mat.define-palette($netskope-red);

// Create the Material theme object
$theme: mat.define-light-theme(
  (
    color: (
      primary: $default-primary-palette,
      accent: $default-accent-palette,
      warn: $default-warn-palette,
    ),
    density: 0,
  )
);

// Add ".theme-default" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-default {
  // Create an Angular Material theme from the $theme map
  @include mat.all-component-themes($theme);

  // Apply the theme to the Fuse Core
  @include fuse-core-theme($theme);

  // Apply the theme to the user components
  @include components-theme($theme);
}

// Globally shared styles
.netskope-gray {
  background-color: #303e47;
  color: white;
}
