// -----------------------------------------------------------------------------------------------------
// @ Theming
// -----------------------------------------------------------------------------------------------------
@use "@angular/material" as mat;
@import "./typography";
@import "styles/common";

@mixin material-theme($theme) {
  @include mat.checkbox-density(-1);
  @include mat.radio-density(-1);
  @include mat.list-density(-2);
  @include mat.icon-button-density(-2);

  // use variables from the following reference:https://material.angular.io/guide/theming-your-components
  $foreground: map-get($theme, "foreground");

  // -----------------------------------------------------------------------------------------------------
  // @ Avatar
  // -----------------------------------------------------------------------------------------------------
  .avatar {
    color: map-get($foreground, text);
  }

  .mat-mdc-table {
    .mat-mdc-header-cell {
      color: map-get($foreground, secondary-text);
    }
  }

  .mat-mdc-tab-header {
    border-bottom: 1px solid map-get($foreground, divider);
  }

  .mat-mdc-input-element::-webkit-calendar-picker-indicator {
    $invert: if(map-get($theme, is-dark), 1, 0);
    filter: invert($invert);
  }
}

// -----------------------------------------------------------------------------------------------------
// @ tabs
// -----------------------------------------------------------------------------------------------------
.mat-mdc-tab-header {
  margin-bottom: 8px;
  .mdc-tab__text-label {
    font-size: mat.font-size($typography, "body-1");
  }
}

// -----------------------------------------------------------------------------------------------------
// @ Avatar
// -----------------------------------------------------------------------------------------------------
.avatar {
  width: 40px;
  min-width: 40px;
  height: 40px;
  line-height: 40px;
  margin: 0 8px 0 0;
  border-radius: 50%;
  font-size: 17px;
  font-weight: 600;
  text-align: center;

  &.square {
    border-radius: 0;
  }

  &.small {
    width: 20px;
    min-width: 20px;
    height: 20px;
    line-height: 20px;
  }

  &.big {
    width: 72px;
    min-width: 72px;
    height: 72px;
    line-height: 72px;
  }

  &.huge {
    width: 96px;
    min-width: 96px;
    height: 96px;
    line-height: 96px;
  }
}

.avatar-wrapper {
  position: relative;

  .avatar {
    margin-top: 0;
    margin-bottom: 0;
  }
  mat-icon.status {
    position: absolute;
    top: 28px;
    left: 28px;
  }
}

// -----------------------------------------------------------------------------------------------------
// @ Card
// -----------------------------------------------------------------------------------------------------

.mat-mdc-dialog-actions {
  &[align="start"] {
    .mdc-button {
      margin-right: $infg-space-sm;
    }
  }
  &[align="end"] {
    .mdc-button {
      margin-left: $infg-space-sm;
    }
  }
}

// -----------------------------------------------------------------------------------------------------
// @ ICON
// -----------------------------------------------------------------------------------------------------

mat-icon.status {
  border-radius: 50%;

  &.online {
    color: #4caf50;

    &:before {
      content: "check_circle";
    }
  }

  &.do-not-disturb {
    color: #f44336;

    &:before {
      content: "remove_circle_outline";
    }
  }

  &.away {
    color: #ffc107;

    &:before {
      content: "access_time";
    }
  }

  &.offline {
    color: #646464;

    &:before {
      content: "not_interested";
    }
  }
}

// -----------------------------------------------------------------------------------------------------
// @ Table
// -----------------------------------------------------------------------------------------------------
.mat-mdc-cell,
.mat-mdc-header-cell {
  &.cdk-column-select {
    width: min-content;
  }
}
