@mixin fuse-widget-theme($theme) {
  $background: map-get($theme, color, background);
  $foreground: map-get($theme, color, foreground);

  fuse-widget {
    > .fuse-widget-front,
    > .fuse-widget-back {
      background: map-get($background, card);
      border-color: map-get($foreground, divider);
    }
  }
}
