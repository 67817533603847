// -----------------------------------------------------------------------------------------------------
// @ Theming
// -----------------------------------------------------------------------------------------------------
@use "sass:color";
@use "@angular/material" as mat;
@import "styles/palette";

/*
// SAMPLE MATERIAL THEME that is generated from mat.define-light-theme or mat.define-dark-theme
$netskopeSampleTheme: (
  density: 0,
  primary: (
    50: #d9faff,
    100: #b7eaf4,
    200: #75d7eb,
    300: #13c3e7,
    400: #00a9ce,
    500: #0294c9,
    600: #047ab5,
    700: #096499,
    800: #0c4b78,
    900: #103b5c,
    A100: #d3ebff,
    A200: #a0d3ff,
    A400: #6dbbff,
    A700: #53b0ff,
    contrast: (
      50: rgba(0, 0, 0, 0.87),
      100: rgba(0, 0, 0, 0.87),
      200: rgba(0, 0, 0, 0.87),
      300: white,
      400: white,
      500: white,
      600: white,
      700: white,
      800: white,
      900: white,
      A100: rgba(0, 0, 0, 0.87),
      A200: rgba(0, 0, 0, 0.87),
      A400: rgba(0, 0, 0, 0.87),
      A700: rgba(0, 0, 0, 0.87),
    ),
    default: #0294c9,
    lighter: #b7eaf4,
    darker: #096499,
    text: #0294c9,
    default-contrast: white,
    lighter-contrast: rgba(0, 0, 0, 0.87),
    darker-contrast: white,
    "50-contrast": rgba(0, 0, 0, 0.87),
    "100-contrast": rgba(0, 0, 0, 0.87),
    "200-contrast": rgba(0, 0, 0, 0.87),
    "300-contrast": white,
    "400-contrast": white,
    "500-contrast": white,
    "600-contrast": white,
    "700-contrast": white,
    "800-contrast": white,
    "900-contrast": white,
    "A100-contrast": rgba(0, 0, 0, 0.87),
    "A200-contrast": rgba(0, 0, 0, 0.87),
    "A400-contrast": rgba(0, 0, 0, 0.87),
    "A700-contrast": rgba(0, 0, 0, 0.87),
    "contrast-contrast": null,
  ),
  accent: (
    50: #d9faff,
    100: #b7eaf4,
    200: #75d7eb,
    300: #13c3e7,
    400: #00a9ce,
    500: #0294c9,
    600: #047ab5,
    700: #096499,
    800: #0c4b78,
    900: #103b5c,
    A100: #d3ebff,
    A200: #a0d3ff,
    A400: #6dbbff,
    A700: #53b0ff,
    contrast: (
      50: rgba(0, 0, 0, 0.87),
      100: rgba(0, 0, 0, 0.87),
      200: rgba(0, 0, 0, 0.87),
      300: white,
      400: white,
      500: white,
      600: white,
      700: white,
      800: white,
      900: white,
      A100: rgba(0, 0, 0, 0.87),
      A200: rgba(0, 0, 0, 0.87),
      A400: rgba(0, 0, 0, 0.87),
      A700: rgba(0, 0, 0, 0.87),
    ),
    default: #00a9ce,
    lighter: #b7eaf4,
    darker: #096499,
    text: #00a9ce,
    default-contrast: white,
    lighter-contrast: rgba(0, 0, 0, 0.87),
    darker-contrast: white,
    "50-contrast": rgba(0, 0, 0, 0.87),
    "100-contrast": rgba(0, 0, 0, 0.87),
    "200-contrast": rgba(0, 0, 0, 0.87),
    "300-contrast": white,
    "400-contrast": white,
    "500-contrast": white,
    "600-contrast": white,
    "700-contrast": white,
    "800-contrast": white,
    "900-contrast": white,
    "A100-contrast": rgba(0, 0, 0, 0.87),
    "A200-contrast": rgba(0, 0, 0, 0.87),
    "A400-contrast": rgba(0, 0, 0, 0.87),
    "A700-contrast": rgba(0, 0, 0, 0.87),
    "contrast-contrast": null,
  ),
  warn: (
    50: #ffe8ec,
    100: #ffd9df,
    200: #fbb7c2,
    300: #f896a1,
    400: #f57578,
    500: #e95d62,
    600: #d43737,
    700: #b81e26,
    800: #8f1416,
    900: #6b1c1d,
    A100: #ffffff,
    A200: #ffeaea,
    A400: #ffb7b8,
    A700: #ff9d9f,
    contrast: (
      50: rgba(0, 0, 0, 0.87),
      100: rgba(0, 0, 0, 0.87),
      200: rgba(0, 0, 0, 0.87),
      300: white,
      400: white,
      500: white,
      600: white,
      700: white,
      800: white,
      900: white,
      A100: rgba(0, 0, 0, 0.87),
      A200: rgba(0, 0, 0, 0.87),
      A400: rgba(0, 0, 0, 0.87),
      A700: rgba(0, 0, 0, 0.87),
    ),
    default: #e95d62,
    lighter: #ffd9df,
    darker: #b81e26,
    text: #e95d62,
    default-contrast: white,
    lighter-contrast: rgba(0, 0, 0, 0.87),
    darker-contrast: white,
    "50-contrast": rgba(0, 0, 0, 0.87),
    "100-contrast": rgba(0, 0, 0, 0.87),
    "200-contrast": rgba(0, 0, 0, 0.87),
    "300-contrast": white,
    "400-contrast": white,
    "500-contrast": white,
    "600-contrast": white,
    "700-contrast": white,
    "800-contrast": white,
    "900-contrast": white,
    "A100-contrast": rgba(0, 0, 0, 0.87),
    "A200-contrast": rgba(0, 0, 0, 0.87),
    "A400-contrast": rgba(0, 0, 0, 0.87),
    "A700-contrast": rgba(0, 0, 0, 0.87),
    "contrast-contrast": null,
  ),
  is-dark: false,
  foreground: (
    base: black,
    divider: rgba(0, 0, 0, 0.12),
    dividers: rgba(0, 0, 0, 0.12),
    disabled: rgba(0, 0, 0, 0.38),
    disabled-button: rgba(0, 0, 0, 0.26),
    disabled-text: rgba(0, 0, 0, 0.38),
    elevation: black,
    hint-text: rgba(0, 0, 0, 0.38),
    secondary-text: rgba(0, 0, 0, 0.54),
    icon: rgba(0, 0, 0, 0.54),
    icons: rgba(0, 0, 0, 0.54),
    text: rgba(0, 0, 0, 0.87),
    slider-min: rgba(0, 0, 0, 0.87),
    slider-off: rgba(0, 0, 0, 0.26),
    slider-off-active: rgba(0, 0, 0, 0.38),
  ),
  background: (
    status-bar: #e0e0e0,
    app-bar: #f5f5f5,
    background: #fafafa,
    hover: rgba(0, 0, 0, 0.04),
    card: white,
    dialog: white,
    disabled-button: rgba(0, 0, 0, 0.12),
    raised-button: white,
    focused-button: rgba(0, 0, 0, 0.12),
    selected-button: #e0e0e0,
    selected-disabled-button: #bdbdbd,
    disabled-button-toggle: #eeeeee,
    unselected-chip: #e0e0e0,
    disabled-list-option: #eeeeee,
    tooltip: #616161,
  ),
);
*/

@mixin colors-theme($theme) {
  // REFER TO THEME VARIABLES at the bottom of core.scss
  $is-dark: map-get($theme, is-dark);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);

  $base-background: map-get($background, card); // card base color mixes better with colors especially in dark theme
  $base-fg: map-get($foreground, base);

  // ---------------------- FOREGROUND COLORS -----------------------------------
  --infg-color-text: #{map-get($foreground, text)}; // 87% of base foreground color
  --infg-color-text-secondary: #{map-get($foreground, secondary-text)}; // 54% of base foreground color
  --infg-color-text-hint: #{map-get($foreground, hint-text)}; // 38% of base foreground color
  --infg-color-text-disabled: #{map-get($foreground, text-disabled)}; // 38% of base foreground color
  --infg-color-border: #{map-get($foreground, divider)}; // 12% of base foreground color
  --infg-color-icon: #{map-get($foreground, icon)}; // 54% of base foreground color

  // ---------------------- BACKGROUND COLORS ------------------------------
  --infg-color-bg: #{map-get($background, background)}; // base background color
  --infg-color-bg-card: #{map-get($background, card)}; // more contrasting than background color
  --infg-color-bg-alter: #{color.adjust(map-get($background, card), $lightness: -5%)}; // use darked for both light and dark mode
  --infg-color-bg-hover: #{map-get($background, hover)}; // 4% of foreground. (not background). Use for hover effect as a layer
  --infg-color-bg-selected: #{map-get($background, selected-button)}; // background with 10% more contrast
  --infg-color-bg-selected-disabled: #{map-get($background, selected-disabled-button)}; // background contrast + 24%
  --infg-color-bg-disabled: #{map-get($background, disabled-button)}; // 12% of foreground (not background)
  --infg-color-bg-tooltip: #{map-get($background, tooltip)}; // background contrast + 60%

  // ---------------------- PRIMARY COLORS ------------------------------
  $primary-color: map-get($primary, default);
  --infg-color-primary-bg: #{color.mix($base-background, $primary-color, 96%)};
  --infg-color-primary-bg-hover: #{color.mix($base-background, $primary-color, 92%)};
  --infg-color-primary-border: #{color.mix($base-background, $primary-color, 50%)};
  --infg-color-primary-border-hover: #{color.mix($base-background, $primary-color, 30%)};
  --infg-color-primary: #{$primary-color}; // main color
  --infg-color-primary-contrast: #{map-get($primary, default-contrast)}; // the default contrast against the main color. black or white.
  --infg-color-primary-hover: #{color.mix($base-background, $primary-color, 20%)}; // a variation for main color for hover interaction
  --infg-color-primary-active: #{color.mix($base-fg, $primary-color, 30%)}; // a variation for main color for pressed, or focus interaction
  --infg-color-primary-icon: #{color.mix($base-background, $primary-color, 20%)}; // icon color
  --infg-color-primary-text: #{color.mix($base-fg, $primary-color, 20%)}; // text color

  // ---------------------- ACCENT COLORS ------------------------------
  $accent-color: map-get($accent, default);
  --infg-color-accent-bg: #{color.mix($base-background, $accent-color, 96%)};
  --infg-color-accent-bg-hover: #{color.mix($base-background, $accent-color, 92%)};
  --infg-color-accent-border: #{color.mix($base-background, $accent-color, 50%)};
  --infg-color-accent-border-hover: #{color.mix($base-background, $accent-color, 30%)};
  --infg-color-accent: #{$accent-color}; // main color.
  --infg-color-accent-contrast: #{map-get($accent, default-contrast)}; // the default contrast against the main color. black or white.
  --infg-color-accent-hover: #{color.mix($base-background, $accent-color, 20%)}; // a variation for main color for hover interaction
  --infg-color-accent-active: #{color.mix($base-fg, $accent-color, 20%)}; // a variation for main color for pressed, or focus interaction
  --infg-color-accent-icon: #{color.mix($base-background, $accent-color, 20%)}; // icon color
  --infg-color-accent-text: #{color.mix($base-fg, $accent-color, 30%)}; // text color

  // ---------------------- LINK COLORS ------------------------------
  --infg-color-link: #{color.mix($base-fg, $primary-color, 30%)};
  --infg-color-link-hover: #{color.mix($base-background, $primary-color, 20%)};
  --infg-color-link-active: #{color.mix($base-fg, $primary-color, 20%)};

  $white87: rgba(255, 255, 255, 0.87);
  // ---------------------- SENTIMENTS COLORS ------------------------------
  $success-color: mat.get-color-from-palette(mat.$green-palette, 600);
  --infg-color-success: #{$success-color};
  --infg-color-success-contrast: $white87;
  --infg-color-success-bg: #{color.mix($base-background, $success-color, if($is-dark, 90%, 95%))};
  --infg-color-success-border: #{color.mix($base-background, $success-color, 30%)};
  --infg-color-success-text: #{$success-color};
  --infg-color-success-icon: #{color.mix($base-background, $success-color, 20%)};

  $info-color: mat.get-color-from-palette($netskope-blue, 600);
  --infg-color-info: #{$info-color};
  --infg-color-success-contrast: white87;
  --infg-color-info-bg: #{map-get($background, hover)}; // exception! neutral/info sentiment bg is grey
  --infg-color-info-border: #{color.mix($base-background, $info-color, 30%)};
  --infg-color-info-text: #{$info-color};
  --infg-color-info-icon: #{color.mix($base-background, $info-color, 20%)};

  $warning-color: mat.get-color-from-palette($netskope-orange, 600);
  --infg-color-warning: #{$warning-color};
  --infg-color-warning-contrast: white87;
  --infg-color-warning-bg: #{color.mix($base-background, $warning-color, if($is-dark, 90%, 95%))};
  --infg-color-warning-border: #{color.mix($base-background, $warning-color, 30%)};
  --infg-color-warning-text: #{color.mix($base-fg, $warning-color, 15%)};
  --infg-color-warning-icon: #{color.mix($base-background, $warning-color, 20%)};

  $error-color: mat.get-color-from-palette($netskope-red, 600);
  --infg-color-error: #{$error-color};
  --infg-color-error-contrast: white87;
  --infg-color-error-bg: #{color.mix($base-background, $error-color, if($is-dark, 90%, 95%))};
  --infg-color-error-border: #{color.mix($base-background, $error-color, 30%)};
  --infg-color-error-text: #{color.mix($base-fg, $error-color, 15%)};
  --infg-color-error-icon: #{color.mix($base-background, $error-color, 20%)};
}

.secondary-text {
  // deprecated. avoid using
  color: var(--infg-color-text-secondary);
}
.disabled-text {
  // deprecated. avoid using
  color: var(--infg-color-text-disabled);
}

.icon,
i {
  color: var(--infg-color-icon);
}

.hint-text {
  color: var(--infg-color-text-hint);
}

.fade-text,
.divider {
  color: var(--infg-fg-divider);
}

a:any-link {
  color: var(--infg-color-link);
  &:hover {
    color: var(--infg-color-link-hover);
  }
  &:focus,
  &:active {
    color: var(--infg-color-link-active);
  }
}

code {
  font-family: "Monaco", "Menlo", "Consolas", "Ubuntu Mono", monospace;
  &:not(.highlight) {
    word-break: break-word;
    color: var(--infg-color-primary);
    padding: 4px;
    border-radius: 4px;
    background-color: var(--infg-color-bg-alter);
  }
}
