@import "styles/palette";

:root {
  // @TODO: CHART Severity level colors based on Design System

  // @TODO: create 10 CHART colors for line chart, donut chart
  // https://www.figma.com/file/YgxAWQ6kEqkQtBhgDCaqPq/V2-Components?type=design&node-id=742-113217&mode=design&t=FSTBzyRqcCTFfO6y-0

  /** ================================ GAUGE CHART COLORS =======================
  * Gauge charts are ideal as scale level chart.
  * When the data requires to show the relation between scores and levels please choose scale level type donut chart.
  *
  * Reference:
  * https://www.figma.com/file/YgxAWQ6kEqkQtBhgDCaqPq/V2-Components?type=design&node-id=25767-11022&mode=design&t=FSTBzyRqcCTFfO6y-0
  */

  /**
  * EXCELLENT level chart color
  * 90 <= level <= 100
  */
  --infg-color-chart-excellent: #{map-get($netskope-green, 700)};
  /**
  * HIGH level chart color
  * 75 <= level < 90
  */
  --infg-color-chart-high: #{map-get($netskope-green, 500)};
  /**
  * MEDIUM level chart color
  * 60 <= level < 75
  */
  --infg-color-chart-medium: #{map-get($netskope-yellow, 300)};
  /**
  * LOW level chart color
  * 50 <= level < 60
  */
  --infg-color-chart-low: #{map-get($netskope-orange, 500)};
  /**
  * POOR level chart color
  * level < 50
  */
  --infg-color-chart-poor: #{map-get($netskope-red, 600)};
}
