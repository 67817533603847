body {
  app,
  .cdk-overlay-container {
    .mat-mdc-card .mat-divider-horizontal.mat-divider-inset {
      position: static;
      margin: 0;
    }
    .mat-mdc-text-field-wrapper {
      padding-left: 0;
      padding-right: 0;
    }
    .mat-mdc-form-field-focus-overlay {
      background-color: transparent !important;
    }
    .mat-mdc-form-field-hint-wrapper,
    .mat-mdc-form-field-error-wrapper {
      padding: 0px;
    }

    .mdc-text-field--filled {
      background-color: transparent !important;
    }
    .mat-mdc-tab-link-container {
      flex-grow: inherit;
    }
    .mat-mdc-select-panel .mat-mdc-option {
      .mat-pseudo-checkbox-full {
        margin-right: 8px;
      }
    }
  }
}
