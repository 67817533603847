@mixin toolbar-theme($theme) {
  $foreground: map-get($theme, color, foreground);

  toolbar {
    .toolbar-separator {
      background: map-get($foreground, divider);
    }

    .mat-badge-content {
      font-size: 10px;
    }
  }
}
