.nav {
  .nav-group {
    display: block;

    > .group-title {
      position: relative;
      display: flex;
      align-items: center;
      min-height: 48px;
      font-weight: 600;
      padding-left: 24px;
      margin-top: 8px;
      font-size: 12px;
      white-space: nowrap;
    }
  }

  .nav-item {
    .nav-link {
      text-decoration: none !important;
      display: flex;
      align-items: center;
      min-height: 48px;
      padding: 0 20px;
      position: relative;
      overflow: hidden;
      cursor: pointer;
      user-select: none;
      color: silver;

      > .nav-link-title {
        flex: 1 1 auto;
        white-space: normal;
      }

      .nav-link-badge {
        display: flex;
        align-items: center;
        min-width: 20px;
        height: 20px;
        padding: 0 7px;
        font-size: 11px;
        font-weight: 600;
        border-radius: 20px;
        transition: opacity 0.3s linear 0.1s;
        margin-left: 8px;

        + .collapsable-arrow {
          margin-left: 8px;
        }
      }

      &:not(.active) {
        &:hover,
        &:focus,
        &:active {
          color: white;
        }
      }
      &.active {
        color: var(--infg-color-accent);
        .nav-link-icon {
          opacity: 1;
        }
        .nav-link-badge {
          background: #ffffff !important;
          color: rgba(0, 0, 0, 0.87) !important;
        }
      }

      .nav-link-icon {
        margin-right: 8px;
      }

      .nav-link-icon,
      .collapsable-arrow {
        font-size: 16px;
        width: 16px;
        height: 16px;
        min-width: 16px;
        min-height: 16px;
        line-height: 16px;
      }
    }

    &.nav-collapsable {
      display: block;

      > .children {
        > .nav-item {
          > .nav-link,
          &.nav-group > .group-title,
          &.nav-group > .group-items > .nav-item > .nav-link {
            padding-left: 44px;
          }

          > .children {
            > .nav-item {
              > .nav-link,
              &.nav-group > .group-title,
              &.nav-group > .group-items > .nav-item > .nav-link {
                padding-left: 60px;
              }
            }
          }
        }
      }
    }
  }

  > .nav-item {
    &.nav-collapsable {
      transition: background 0.3s;
    }
  }

  > .nav-group {
    > .group-items {
      > .nav-collapsable {
        background: transparent;
        transition: background 0.3s;

        &.open {
          background: rgba(0, 0, 0, 0.12);
        }
      }
    }
  }

  &.vertical {
    .nav-group {
      .group-title {
        text-transform: uppercase;
      }
    }
  }
}
